import {
    ECOM_TEST_FAILURE,ECOM_TEST_SUCCESS,ECOM_TEST_REQUEST,
    CHECK_ORDERING_REQUEST,CHECK_ORDERING_SUCCESS,CHECK_ORDERING_FAILURE,INIT_STORE,  
   } from "../types";

const getEcomTestStatus =  {
    data:[],
    isLoading:null
}

const storeDataStatus = {
    data:[],
    initStore : false,
    isLoading:null
}

export const ecomTest= (state = getEcomTestStatus, { type, payload }) => {
    switch (type){
        case ECOM_TEST_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case ECOM_TEST_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case ECOM_TEST_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const storeData= (state = storeDataStatus, { type, payload }) => {
    switch (type){ 
        case INIT_STORE:
            state.isLoading = false;
            state.initStore = payload;
            return {
                ...state
            }
        case CHECK_ORDERING_REQUEST:
            state.isLoading = true;
            state.initStore = false;
            return {
                ...state
            }
            case CHECK_ORDERING_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            state.initStore = false;
            return {
                ...state
            }
            case CHECK_ORDERING_SUCCESS:
                state.isLoading = false;
                state.initStore = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

