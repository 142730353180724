import { FETCH_PROFILE_REQUEST,FETCH_PROFILE_SUCCESS,FETCH_PROFILE_FAILURE ,PROFILE_UPDATE_REQUEST,PROFILE_UPDATE_SUCCESS,PROFILE_UPDATE_FAILURE,CLEAR_PROFILE} from  '../types';
const profileInfo =  {
    profileData:[],
    profileUpdatedSuccessfully : null,
    isLoading:null,
    profileUpdateErr:null
}

export const userProfile= (state = profileInfo, { type, payload }) => {
    switch (type){
        case FETCH_PROFILE_REQUEST:
        state.isLoading = true;
        state.profileUpdatedSuccessfully = null;
        state.profileUpdateErr = null;
        return {
            ...state
        }

        case FETCH_PROFILE_SUCCESS:
        state.isLoading = false;
        state.profileData=payload;
        return {
            ...state
        }

        case FETCH_PROFILE_FAILURE:
        state.isLoading = false;
        return {
            ...state
        }

        case PROFILE_UPDATE_REQUEST:
        state.isLoading = true;
        state.profileUpdatedSuccessfully = null; 
        state.profileUpdateErr = null;
        return {
            ...state
        }

        case PROFILE_UPDATE_SUCCESS:
        state.isLoading = false;
        state.profileUpdatedSuccessfully = true; 
        state.profileData=payload;
        state.profileUpdateErr = null;
        return {
            ...state
        }

        case PROFILE_UPDATE_FAILURE:
        state.isLoading = false;
        state.profileUpdatedSuccessfully = false;
        state.profileUpdateErr= payload.response ? payload.response.statusText : payload.message ;
        return {
            ...state
        }

        case CLEAR_PROFILE:
        state.profileData=[];
        state.profileUpdatedSuccessfully = null;
        state.isLoading=null;
        state.profileUpdateErr=null;
        return {
            ...state
        }
        
        default:
        return state;
    }
}
