

//set lang
export const SET_LANGUAGE="SET_LANGUAGE";


//initloader
export const INIT_LOADER="INIT_LOADER";
export const HALT_LOADER="HALT_LOADER";

//signup
export const ERRORS="ERRORS";
export const OPEN_OTP="OPEN_OTP";
export const OTP_SEND_SUCCESS="OTP_SEND_SUCCESS";
export const REGISTERATION_SUCCESS="REGISTERATION_SUCCESS";

//login
export const LOGIN_FAIL="LOGIN_FAIL";
export const LOGIN="LOGIN";
export const SET_USER = "SET_USER";

//logout
export const LOGOUT="LOGOUT";

//chnage password
export const PASSWORD_UPDATE_REQUEST="PASSWORD_UPDATE_REQUEST";
export const PASSWORD_UPDATE_SUCCESS="PASSWORD_UPDATE_SUCCESS";
export const PASSWORD_UPDATE_FAILURE="PASSWORD_UPDATE_FAILURE";

//order
export const FETCH_ORDERS_FAILURE="FETCH_ORDERS_FAILURE";
export const FETCH_ORDERS_REQUEST="FETCH_ORDERS_REQUEST";
export const FETCH_ORDERS_SUCCESS="FETCH_ORDERS_SUCCESS";
export const CLEAR_ORDERS_DATA="CLEAR_ORDERS_DATA";

export const FETCH_ORDER_DETAIL_FAILURE="FETCH_ORDER_DETAIL_FAILURE";
export const FETCH_ORDER_DETAIL_REQUEST="FETCH_ORDER_DETAIL_REQUEST";
export const FETCH_ORDER_DETAIL_SUCCESS="FETCH_ORDER_DETAIL_SUCCESS";

//booking
export const FETCH_BOOKINGS_FAILURE="FETCH_BOOKINGS_FAILURE";
export const FETCH_BOOKINGS_REQUEST="FETCH_BOOKINGS_REQUEST";
export const FETCH_BOOKINGS_SUCCESS="FETCH_BOOKINGS_SUCCESS";

export const FETCH_BOOKING_DETAIL_FAILURE="FETCH_BOOKING_DETAIL_FAILURE";
export const FETCH_BOOKING_DETAIL_REQUEST="FETCH_BOOKING_DETAIL_REQUEST";
export const FETCH_BOOKING_DETAIL_SUCCESS="FETCH_BOOKING_DETAIL_SUCCESS";

export const CLEAR_BOOKINGS_DATA="CLEAR_BOOKINGS_DATA";

//profile
export const FETCH_PROFILE_REQUEST="FETCH_PROFILE_REQUEST";
export const FETCH_PROFILE_SUCCESS="FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE="FETCH_PROFILE_FAILURE";

export const PROFILE_UPDATE_REQUEST="PROFILE_UPDATE_REQUEST";
export const PROFILE_UPDATE_SUCCESS="PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_FAILURE="PROFILE_UPDATE_FAILURE";

export const CLEAR_PROFILE="CLEAR_PROFILE";

//address
export const FETCH_ADDRESS_REQUEST="FETCH_ADDRESS_REQUEST";
export const FETCH_ADDRESS_SUCCESS="FETCH_ADDRESS_SUCCESS";
export const FETCH_ADDRESS_FAILURE="FETCH_ADDRESS_FAILURE";

export const FETCH_STORE_LOCALITIES_REQUEST="FETCH_STORE_LOCALITIES_REQUEST";
export const FETCH_STORE_LOCALITIES_SUCCESS="FETCH_STORE_LOCALITIES_SUCCESS";
export const FETCH_STORE_LOCALITIES_FAILURE="FETCH_STORE_LOCALITIES_FAILURE";

export const ADDRESS_ADD_REQUEST="ADDRESS_ADD_REQUEST";
export const ADDRESS_ADD_SUCCESS="ADDRESS_ADD_SUCCESS";
export const ADDRESS_ADD_FAILURE="ADDRESS_ADD_FAILURE";

export const ADDRESS_UPDATE_REQUEST="ADDRESS_UPDATE_REQUEST";
export const ADDRESS_UPDATE_SUCCESS="ADDRESS_UPDATE_SUCCESS";
export const ADDRESS_UPDATE_FAILURE="ADDRESS_UPDATE_FAILURE";

export const ADDRESS_DELETE_REQUEST="ADDRESS_DELETE_REQUEST";
export const ADDRESS_DELETE_SUCCESS="ADDRESS_DELETE_SUCCESS";
export const ADDRESS_DELETE_FAILURE="ADDRESS_DELETE_FAILURE";

export const CLEAR_ADDRESS="CLEAR_ADDRESS";

//test
export const ECOM_TEST_REQUEST="ECOM_TEST_REQUEST";
export const ECOM_TEST_SUCCESS="ECOM_TEST_SUCCESS";
export const ECOM_TEST_FAILURE="ECOM_TEST_FAILURE";

//check for ordering
export const CHECK_ORDERING_REQUEST="CHECK_ORDERING_REQUEST";
export const CHECK_ORDERING_SUCCESS="CHECK_ORDERING_SUCCESS";
export const CHECK_ORDERING_FAILURE="CHECK_ORDERING_FAILURE";

export const INIT_STORE="INIT_STORE";

export const PRODUCTS = "PRODUCTS";
export const CHAIN_DETAILS = "CHAIN_DETAILS";
export const STORE_DETAILS = "STORE_DETAILS";
export const BRANDS = "BRANDS";
export const CATEGORIES = "CATEGORIES";
export const BANNERS = "BANNERS";
export const SET_RECORD_COUNT = "SET_RECORD_COUNT";
export const SET_OFFSET = "SET_OFFSET";
export const SET_REQUEST_DATA = "SET_REQUEST_DATA";
export const SET_SORT_VALUE = "SET_SORT_VALUE";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const CART_PRODUCTS = "CART_PRODUCTS";
export const SUB_CATEGORIES = "SUB_CATEGORIES";
export const TAXES = "TAXES";
export const CHARGES = "CHARGES";
export const GROSS_BILL = "GROSS_BILL";
export const ORDER_CHARGES = "ORDER_CHARGES";
export const TOP_SELLING_PRODUCTS = "TOP_SELLING_PRODUCTS";
export const RECOMMENDED_PRODUCTS = "RECOMMENDED_PRODUCTS";
//search products

export const SEARCH_PRODUCT_BARCODE_REQUEST="SEARCH_PRODUCT_BARCODE_REQUEST";
export const SEARCH_PRODUCT_BARCODE_SUCCESS="SEARCH_PRODUCT_BARCODE_SUCCESS";
export const SEARCH_PRODUCT_BARCODE_FAILURE="CHECK_ORDERING_FAILURE";

export const SET_LOGIN_MODAL="SET_LOGIN_MODAL";
export const SET_SIGNUP_MODAL="SET_SIGNUP_MODAL";
export const SET_VERIFY_MOBILE_MODAL="SET_VERIFY_MOBILE_MODAL";
export const SET_CHANGE_PASSWORD_MODAL="SET_CHANGE_PASSWORD_MODAL";
export const SET_SIGNUP_DETAILS_MODAL="SET_SIGNUP_DETAILS_MODAL";
export const RESET_MODALS="RESET_MODALS";


// others Reducer
export const UPDATE_CURRENT_THEME = "UPDATE_CURRENT_THEME";