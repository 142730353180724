import { FETCH_ADDRESS_REQUEST,FETCH_ADDRESS_SUCCESS,FETCH_ADDRESS_FAILURE ,ADDRESS_ADD_REQUEST,ADDRESS_ADD_SUCCESS,ADDRESS_ADD_FAILURE,ADDRESS_UPDATE_REQUEST,ADDRESS_UPDATE_SUCCESS,ADDRESS_UPDATE_FAILURE,ADDRESS_DELETE_REQUEST,ADDRESS_DELETE_SUCCESS,ADDRESS_DELETE_FAILURE,CLEAR_ADDRESS,FETCH_STORE_LOCALITIES_REQUEST,FETCH_STORE_LOCALITIES_FAILURE,FETCH_STORE_LOCALITIES_SUCCESS} from  '../types';
const addressInfo =  {
    deliveryAddress:[],
    storeLocalities:[],
    addressAddedSuccessfully : null,
    addressUpdatedSuccessfully : null,
    addressDeletedSuccessfully : null,
    isLoading:null,
    addressAddErr:null,
    addressUpdateErr:null,
    addressDeleteErr:null
}

export default (state = addressInfo, { type, payload }) => {
    switch (type){
        case FETCH_ADDRESS_REQUEST:
        state.isLoading = true;
        state.addressAddedSuccessfully = null;
        state.addressUpdatedSuccessfully = null;
        state.addressDeletedSuccessfully = null; 
        return {
            ...state
        }
        case FETCH_ADDRESS_SUCCESS:
        state.isLoading = false;
        state.deliveryAddress=payload;
        return {
            ...state
        }
        case FETCH_ADDRESS_FAILURE:
        state.isLoading = false;
        return {
            ...state
        }

        case FETCH_STORE_LOCALITIES_REQUEST:
        state.isLoading = true; 
        return {
            ...state
        }
        case FETCH_STORE_LOCALITIES_SUCCESS:
        state.isLoading = false;
        state.storeLocalities=payload;
        return {
            ...state
        }
        case FETCH_STORE_LOCALITIES_FAILURE:
        state.isLoading = false;
        return {
            ...state
        }

        case ADDRESS_ADD_REQUEST:
        state.isLoading = true;
        state.addressAddedSuccessfully = null;
        state.addressUpdatedSuccessfully = null;
        state.addressDeletedSuccessfully = null; 
        state.addressAddErr = null;
        return {
            ...state
        }
        case ADDRESS_ADD_SUCCESS:
        state.isLoading = false;
        state.deliveryAddress=payload;
        state.addressAddErr = null;
        state.addressAddedSuccessfully = true; 
        return {
            ...state
        }
        case ADDRESS_ADD_FAILURE:
        state.isLoading = false;
        state.addressAddedSuccessfully = false;
        state.addressAddErr=payload.response && payload.response.statusText;
        return {
            ...state
        }

        case ADDRESS_UPDATE_REQUEST:
        state.isLoading = true;
        state.addressAddedSuccessfully = null;
        state.addressUpdatedSuccessfully = null;
        state.addressDeletedSuccessfully = null; 
        state.addressUpdateErr = null;
        return {
            ...state
        }
        case ADDRESS_UPDATE_SUCCESS:
        state.isLoading = false;
        state.addressUpdatedSuccessfully = true; 
        // state.deliveryAddress= [...state.deliveryAddress.filter(m=>m.ID != payload[0].ID),...payload];
        state.addressUpdateErr = null;
        return {
            ...state
        }
        case ADDRESS_UPDATE_FAILURE:
        state.isLoading = false;
        state.addressUpdatedSuccessfully = false;
        state.addressUpdateErr=payload.response && payload.response.statusText;
        return {
            ...state
        }

        case ADDRESS_DELETE_REQUEST:
        state.isLoading = true;
        state.addressAddedSuccessfully = null;
        state.addressUpdatedSuccessfully = null;
        state.addressDeletedSuccessfully = null;  
        state.addressDeleteErr = null;
        return {
            ...state
        }
        case ADDRESS_DELETE_SUCCESS:
        state.isLoading = false;
        state.addressDeletedSuccessfully = true; 
        state.deliveryAddress=payload;
        state.addressDeleteErr = null;
        return {
            ...state
        }
        case ADDRESS_DELETE_FAILURE:
        state.isLoading = false;
        state.addressDeletedSuccessfully = false;
        state.addressDeleteErr=payload.response && payload.response.statusText;
        return {
            ...state
        }

        case CLEAR_ADDRESS:
        state.deliveryAddress=[];
        state.addressAddedSuccessfully = null;
        state.addressUpdatedSuccessfully = null;
        state.addressDeletedSuccessfully = null;
        state.isLoading=null;
        state.addressAddErr=null;
        state.addressUpdateErr=null;
        state.addressDeleteErr=null;
        return {
            ...state
        }
        
        default:
        return state;
    }
}
