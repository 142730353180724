import {
    FETCH_BOOKINGS_FAILURE,
    FETCH_BOOKINGS_REQUEST,
    FETCH_BOOKINGS_SUCCESS,

    FETCH_BOOKING_DETAIL_FAILURE,
    FETCH_BOOKING_DETAIL_REQUEST,
    FETCH_BOOKING_DETAIL_SUCCESS,

    CLEAR_BOOKINGS_DATA,
} from '../types';

const initialState = {
    bookingsList : [],
    bookingDetail : {},
    bookingView:'ongoingBookings',
    isBookingsListFetched : false
}

export default (state = initialState, { type, payload }) => {
    switch (type){
        case FETCH_BOOKINGS_REQUEST:
        state.bookingsList = [];
        state.isBookingsListFetched= false
        return {
            ...state
        }
        case FETCH_BOOKINGS_SUCCESS:
        state.bookingsList = payload.data;
        state.isBookingsListFetched= true
        return {
            ...state
        }
        case FETCH_BOOKINGS_FAILURE:
        state.bookingsList = [];
        state.isBookingsListFetched= false
        return {
            ...state
        }
        case FETCH_BOOKING_DETAIL_REQUEST:
        state.bookingDetail = {}
        return {
            ...state
        }
        case FETCH_BOOKING_DETAIL_SUCCESS:
        state.bookingDetail = payload
        return {
            ...state
        }
        case FETCH_BOOKING_DETAIL_FAILURE:
        state.bookingDetail = {}
        return {
            ...state
        }
      
        case CLEAR_BOOKINGS_DATA:
        state.bookingsList = [];
        state.bookingDetail = {}
        return {
            ...state
        }
        
        default:
        return state;
    }
}