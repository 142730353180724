import { SET_LOGIN_MODAL,SET_SIGNUP_MODAL,SET_VERIFY_MOBILE_MODAL,SET_CHANGE_PASSWORD_MODAL,RESET_MODALS,SET_SIGNUP_DETAILS_MODAL} from '../types';

const initialState = {
    loginModalStatus:'close',
    signupModalStatus:'close',
    verifyMobileModalStatus : 'close', 
    changePasswordModalStatus:'close',
    signupDetailsModalStatus:'close'
}

export default (state = initialState, { type, payload }) => {
    switch (type){
        case SET_LOGIN_MODAL:
        state.loginModalStatus = payload;
        state.signupDetailsModalStatus = 'close';
        state.signupModalStatus = 'close';
        state.verifyMobileModalStatus = 'close';
        state.changePasswordModalStatus = 'close';
        return{
            ...state
        }

        case SET_SIGNUP_MODAL:
        state.signupModalStatus = payload;
        state.signupDetailsModalStatus = 'close';
        state.loginModalStatus = 'close';
        state.verifyMobileModalStatus = 'close';
        state.changePasswordModalStatus = 'close';
        return{
            ...state
        }

        case SET_VERIFY_MOBILE_MODAL:
        state.verifyMobileModalStatus = payload;
        state.signupDetailsModalStatus = 'close';
        state.loginModalStatus = 'close';
        state.signupModalStatus = 'close';
        state.changePasswordModalStatus = 'close';
        return{
            ...state
        }

        case SET_CHANGE_PASSWORD_MODAL:
        state.changePasswordModalStatus = payload;
        state.signupDetailsModalStatus = 'close';
        state.loginModalStatus = 'close';
        state.signupModalStatus = 'close';
        state.verifyMobileModalStatus = 'close';
        return{
            ...state
        }

        case SET_SIGNUP_DETAILS_MODAL:
        state.signupDetailsModalStatus = payload;
        state.changePasswordModalStatus = 'close';
        state.loginModalStatus = 'close';
        state.signupModalStatus = 'close';
        state.verifyMobileModalStatus = 'close';
        return{
            ...state
        }

        case RESET_MODALS:
        state.loginModalStatus = 'close';
        state.signupModalStatus = 'close';
        state.verifyMobileModalStatus = 'close';
        state.changePasswordModalStatus = 'close';
        state.signupDetailsModalStatus = 'close';
        return{
            ...state
        }

        default:
        return state;
    }
}