import {
    FETCH_ORDERS_SUCCESS,
    FETCH_ORDER_DETAIL_SUCCESS,
    CLEAR_ORDERS_DATA
} from '../types';

const initialState = {
    ordersList : [],
    orderDetail : {}
}

export default (state = initialState, { type, payload }) => {
    switch (type){
        case FETCH_ORDERS_SUCCESS:
        state.ordersList = payload.data
        return {
            ...state
        }
        case FETCH_ORDER_DETAIL_SUCCESS:
        state.orderDetail = payload
        return {
            ...state
        }
        case CLEAR_ORDERS_DATA:
        state.ordersList = [];
        state.orderDetail = {}
        return {
            ...state
        }
        
        default:
        return state;
    }
}