import React, { Component, Fragment } from 'react';
import { Provider } from 'react-redux'
import configureStore from './store';
import './App.scss';

import { BrowserRouter } from 'react-router-dom';
import { initializeFirebase } from './components/new/PushNotification';

const AppRoutes = React.lazy(() => import('./components/common/AppRoutes'));
const WebRoutes = React.lazy(() => import('./components/common/WebRoutes'));

const { store } = configureStore();

class App extends Component {
  componentDidMount() {
    if (process.env.REACT_APP_COUNTRY_CODE !== "in") {

      document.getElementById('favicn').href = process.env.REACT_APP_STORE_POCKET_LOGO_URL;
    }

  }
   
  render() {
    initializeFirebase();

    return (
      <Provider store={store}>
        <BrowserRouter>
          <Fragment>
            <div
              className="App"
              style={{
                height: "100%",
                minWidth: window.screen.width >= 760 ? 1300 : 360,
                maxWidth: window.screen.width >= 760 ? 1500 : 500,
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#fff",
              }}
            >
              <React.Suspense fallback={<></>}>
                {window.screen.width >= 760 && <WebRoutes />}
                {window.screen.width < 760 && <AppRoutes />}
              </React.Suspense>
            </div>
          </Fragment>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;

