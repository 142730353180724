
import { combineReducers } from 'redux';
import {ecomTest} from './setupReducer';
import {setLanguage}  from './helperReducer';
import { initLoader } from './loaderReducer';
import productsReducer from './productsReducer';
import ordersReducer from './ordersReducer';
import bookingsReducer from './bookingsReducer';
import {storeData} from './setupReducer';
import authReducer from './authReducer';
import {userProfile} from './profileReducer'
import addressReducer from './addressReducer'
import customReducer from './customReducer';
import {additionalReducer} from './additionalReducer';
export default combineReducers({
    // getEcomTestStatus:ecomTest,
    // languageStatus:setLanguage,
    // loaderStatus:initLoader,
    loaderStatus:initLoader,
    data: productsReducer,
    storeData,
    auth: authReducer,
    orders : ordersReducer,
    bookings : bookingsReducer,
    userProfile,
    addressData:addressReducer,
    modalStatus:customReducer,
    additionalReducer
}); 