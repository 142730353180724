import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyBaKnIPEYIaNMFAMZjpx987IXkvD2C_7Y8",
  authDomain: "qb-lite.firebaseapp.com",
  databaseURL: "https://qb-lite.firebaseio.com",
  projectId: "qb-lite",
  storageBucket: "qb-lite.appspot.com",
  messagingSenderId: "458658454220",
  appId: "1:458658454220:web:f1b3988bebfcafdba2c069",
  measurementId: "G-XBK103FGGD"
};

export const initializeFirebase = () => {
  firebase.initializeApp(firebaseConfig);
  // navigator.serviceWorker
  // .register('/FirebaseServiceWoerker.js')
  // .then((registration) => {
  //   firebase.messaging().useServiceWorker(registration);
  // });

}

export const askForPermissionToReceiveNotifications = async () => {
    try {
      const messaging = firebase.messaging();
      await messaging.requestPermission();
      const token = await messaging.getToken();
      console.log('token to use:', token);
      localStorage.setItem("FCMToken",token);
      return token;
    } catch (error) {
      console.error(error);
    }
  }