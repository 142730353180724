import { PRODUCTS,CHAIN_DETAILS,STORE_DETAILS,BRANDS,BANNERS,CATEGORIES,SET_RECORD_COUNT,SET_OFFSET,SET_REQUEST_DATA,SET_SORT_VALUE,CART_PRODUCTS,SUB_CATEGORIES,TAXES,CHARGES,GROSS_BILL,ORDER_CHARGES,TOP_SELLING_PRODUCTS,RECOMMENDED_PRODUCTS } from '../types';

const initialState = {
    products: [],
    storeDetails:{},
    chainDetails:{},
    brands:[],
    categories:[],
    banners:[],
    totalRecords: 0,
    hideOutOfStockProducts: 0,
    showDiscountedProducts: 0,
    sortValue: 'NAME_A_TO_Z',
    offset: 0,
    cartProducts: [],
    subCategories: [],
    taxes: [],
    charges: [],
    grossBill: 0,
    rounding: 0,
    orderCharges: [],
    topSellingProducts: []
};

export default function(state = initialState, action){

    switch (action.type){
        
        case PRODUCTS:
             
            console.log(action)
            var {products,totalRecordCount} = action.payload;

            return {
                ...state,
                products: products,
                totalRecords: totalRecordCount
            }

        case CHAIN_DETAILS:
        
            return {
                ...state,
                chainDetails: action.payload
            }

        case STORE_DETAILS:
            
            return {
                ...state,
                storeDetails: action.payload
            }
        
        case BRANDS:

            return{
                ...state,
                brands: action.payload
            }
        
        case CATEGORIES:

            return{
                ...state,
                categories: action.payload
            }

        case BANNERS:

        return{
            ...state,
            banners: action.payload
        }

        case SET_RECORD_COUNT: 
            
            return{
                ...state,
                totalRecords: action.payload
            }

        case SET_OFFSET: 
            
            return{
                ...state,
                offset: action.payload
            }

        case SET_REQUEST_DATA: 

            var {brands,categories,hideOutOfStockProducts,showDiscountedProducts,sortValue} = action.payload;

            return{
                ...state,
                brands,
                categories,
                hideOutOfStockProducts,
                showDiscountedProducts,
                sortValue,
                offset: 0
            }

        case SET_SORT_VALUE: 

            return{
                ...state,
                sortValue: action.payload,
                offset: 0
            }

        case CART_PRODUCTS:

            return{
                ...state,
                cartProducts : action.payload
            }

        case TOP_SELLING_PRODUCTS:

            return{
                ...state,
                topSellingProducts : action.payload
            }
        case RECOMMENDED_PRODUCTS:

            return{
                ...state,
                recommendedProducts : action.payload
            }

        case SUB_CATEGORIES:

            return{
                ...state,
                subCategories: action.payload
            }

        case TAXES: 

            return{
                ...state,
                taxes: action.payload
            }

        case CHARGES:

            return {
                ...state,
                charges: action.payload
            }

        case GROSS_BILL:
                
            return {
                ...state,
                grossBill: action.payload.grossBill,
                rounding: action.payload.rounding,
                totalProductLevelAdditionalCharges:action.payload.totalProductLevelAdditionalCharges,
                totalOrderChargeValue:action.payload.totalOrderChargeValue,
                totalProdTaxValue:action.payload.totalProdTaxValue,
                orderTotalTaxValue:action.payload.orderTotalTaxValue,
                deliveryZipCodes:action.payload.deliveryZipCodes,
        
            }

        case ORDER_CHARGES:

            return{
                ...state,
                orderCharges: action.payload
            }

        default:
            return state;
    }
}