import { UPDATE_CURRENT_THEME } from "../types";

const additionalState = {
    theme: {},
}
 let additionalReducer = (state = additionalState, action) => {
    switch(action.type){
        case UPDATE_CURRENT_THEME:
            return {
                ...state,
                theme: action.payload
            }
        default:
            return state;
    }
}

export {additionalReducer};