import { LOGIN, LOGIN_FAIL,ERRORS, CHECK_LOGIN, CHECK_LOGIN_FAIL,LOGOUT,OPEN_OTP, OTP_SEND_SUCCESS,CLOSE_OTP, REGISTERATION_SUCCESS, GET_STARTED,SET_USER,PASSWORD_UPDATE_REQUEST,PASSWORD_UPDATE_SUCCESS,PASSWORD_UPDATE_FAILURE,SOCIAL_PROFILE_INFO,SET_ONBOARD_STATUS } from '../types';

const initialState = {
    isLoggedIn: false,
    isPhoneVerified : null,
    data:{},
    otp:'STAND_BY',
    otpSent:false,
    registerationSuccess:false,
    serverOtp:null,
    errorMsg:null,
    passwordUpdatedSuccessfully : null,
    updatePasswordErrMsg:null,
    // socialProfileObj:null,
    // isOnboard: null,
}

export default (state = initialState, { type, payload }) => {
    switch (type){
        case LOGIN:
        state.isLoggedIn = true;
        // state.isOnboard = true;
        // state.token = payload.token;
        state.data = payload.sessionData ? payload.sessionData.data[0] : payload.data[0];
        state.isPhoneVerified = payload.sessionData ? payload.sessionData.data[0].isPhoneVerified : payload.data[0].isPhoneVerified;
        localStorage.setItem('token', payload.token)
        localStorage.setItem('deviceID', payload.sessionData ? payload.sessionData.deviceID : payload.deviceID)
        localStorage.setItem('customerID',payload.sessionData ? payload.sessionData.data[0].globalEcomCustomerID : payload.data[0].globalEcomCustomerID)
       
        return {
            ...state
        }

        // case SET_ONBOARD_STATUS:
        // state.isLoggedIn = false;
        // state.isOnboard = false;
        // return{
        //     ...state
        // }

        // case SOCIAL_PROFILE_INFO:
        // state.isLoggedIn = false;
        // state.isOnboard = null;
        // state.socialProfileObj = payload;
        // return{
        //     ...state
        // }

        case SET_USER:
        state.isLoggedIn = true;
        state.data = payload.sessionData ? payload.sessionData.data[0] : payload.data[0];
        return{
            ...state
        }

        case ERRORS:
        state.registerationSuccess=false;
        state.isLoggedIn = false;
        // state.isOnboard = null;
        state.otpSent=false;
        state.otp = 'STAND_BY';
        state.errorMsg = (payload && payload.message) ? payload.message : payload;
        state.isPhoneVerified = (payload && (payload.isPhoneVerified==1 || payload.isPhoneVerified==0)) ?  payload.isPhoneVerified : null;
        return {
            ...state
        }
        
        case LOGIN_FAIL:
        state.isLoggedIn = false;

        return {
            ...state
        }

        // case CHECK_LOGIN:
        // state.isLoggedIn = payload.status;
        // state.token = payload.token
        // state.data = payload.sessionData
        // return{
        //     ...state
        // }
        // case CHECK_LOGIN_FAIL:
        // localStorage.clear()
        // return{
        //     ...state
        // }
        
        case LOGOUT:
        localStorage.removeItem('token')
        localStorage.removeItem('customerID')
        state.isLoggedIn = false;
        state.data = {};
        state.isPhoneVerified = null;
        state.otp='STAND_BY';
        state.otpSent=false;
        state.registerationSuccess=false;
        state.serverOtp=null;
        state.errorMsg=null;
        state.passwordUpdatedSuccessfully = null;
        state.updatePasswordErrMsg=null;
        return{
            ...state
        }



        case OPEN_OTP:
        state.otp = 'OPEN';
        state.serverOtp = null;
        return {
            ...state
        }
        case OTP_SEND_SUCCESS:
        state.otpSent = true;
        state.otp = 'STAND_BY';
        return {
            ...state
        }
        // case CLOSE_OTP:
        // state.otp = 'CLOSE';
        // state.registerationSuccess = payload.registerationSuccess;
        // state.serverOtp = payload.serverOtp;
        // return {
        //     ...state
        // }
        case REGISTERATION_SUCCESS:
        state.otp = 'CLOSE';
        state.registerationSuccess = true;
        state.serverOtp = null;
        state.data = payload.sessionData ? payload.sessionData.data[0] : payload.data[0];
        state.isPhoneVerified = payload.sessionData ? payload.sessionData.data[0].isPhoneVerified : payload.data[0].isPhoneVerified;
        localStorage.setItem('token', payload.token)
        localStorage.setItem('deviceID', payload.sessionData ? payload.sessionData.deviceID : payload.deviceID)
        localStorage.setItem('customerID',payload.sessionData ? payload.sessionData.data[0].globalEcomCustomerID : payload.data[0].globalEcomCustomerID)
        return {
            ...state
        }

        case PASSWORD_UPDATE_REQUEST:
        state.isLoading = true;
        state.passwordUpdatedSuccessfully = null; 
        state.updatePasswordErrMsg = null;
        return {
            ...state
        }

        case PASSWORD_UPDATE_SUCCESS:
        state.isLoading = false;
        state.passwordUpdatedSuccessfully = true; 
        state.updatePasswordErrMsg = null;
        return {
            ...state
        }

        case PASSWORD_UPDATE_FAILURE:
        state.isLoading = false;
        state.passwordUpdatedSuccessfully = false;
        state.updatePasswordErrMsg = payload;
        return {
            ...state
        }

        // case GET_STARTED:
        // state.isLoggedIn = true;
        // state.registerationSuccess = false;
        // state.otp = 'STAND_BY'
        // return {
        //     ...state
        // }
        default:
            return state;
    }
}